import React from "react"
import Layout from "hoc/Layout"
import Header from "containers/Header/Header"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import QuestionsSection from "containers/FAQ/QuestionsSection"

const FAQ = () => {
  const breadcrumbsData = [
    {
      title: "Startseite",
      link: "/",
    },
    {
      title: "Für Kunden",
      link: "/fur-kunden/phasen-des-vertragsabschlusses",
    },
    "Fragen und Antworten",
  ]

  const { faqMain } = useStaticQuery(
    graphql`
      query {
        faqMain: file(name: { eq: "faq-main" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "#e5f5f7"
              quality: 95
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const mainImage = getImage(faqMain)
  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle="Fragen und Antworten">
      <Header
        title={"Fragen und Antworten"}
        titleDescription={"Antworten auf häufig gestellte Fragen"}
        mainImage={mainImage}
        mainImageAlt={"Fragen und Antworten"}
      />
      <QuestionsSection />
    </Layout>
  )
}

export default FAQ
